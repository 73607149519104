.center_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  width: 100%;
  max-width: 840px;
}

.center_container_upper {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #eaebf0;
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}

.center_container_upper .title {
  color: var(--gray-700, #272d37);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.1px;
}

.center_container_upper .desc {
  color: var(--gray-50, #5f6d7e);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.1px;
}

.center_container_upper div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.center_container_upper .service_title {
  color: var(--gray-700, #272d37);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.1px;
}

.center_container_upper .service_desc {
  color: var(--gray-50, #5f6d7e);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.1px;
}

.center_container_lower {
  padding-top: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;
}

.service_card_container {
  width: 247px;
  height: 250px;
}

.service_card_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service_card_container p {
  color: var(--gray-50, #5f6d7e);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.1px;
}

@media screen and (max-width: 543px) {
  .service_card_container {
    width: 100%;
  }
}
