* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: black;
}

ul {
  list-style: none;
}

.primary-btn,
.secondary-btn,
.sky_blue-btn {
  font-size: 15px;
  font-weight: 600;
  padding: 12px 18px;
  color: #b1ccfb;
  background-color: #ee1b22;
  border: 0.8px solid #b63939;
  border-radius: 6px;
  font-family: "Inter";
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.primary-btn:hover {
  /* color: #112b9f; */
  background-color: #c52424;
}

.sky_blue-btn {
  background-color: #437ef7;
  border: 0.8px solid #437ef7;
  color: #fff;
}
.sky_blue-btn:hover {
  background-color: #fff;
  color: #437ef7;
}

.secondary-btn:hover {
  background-color: #02320a;
  color: #fff;
}
.secondary-btn {
  background-color: #fff;
  color: #272d37;
}

/* ::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
}

::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
   -webkit-border-radius: 10px !important;
   border-radius: 10px !important;
}

::-webkit-scrollbar-thumb {
   -webkit-border-radius: 10px !important;
   border-radius: 10px !important;
   background: #11131C !important; 
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5) !important; 

}
::-webkit-scrollbar-thumb:window-inactive {
   background: #11131C !important; 
} */
