.faq_main{
    padding: 50px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.faq_main .leftsvg{
    width: 643px;
    height: 493px;
    flex-shrink: 0;
    fill: rgba(2, 50, 10, 0.03);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%,-50%);
    z-index: 1;
}
.faq_main .rightsvg{
    width: 684.966px;
    height: 571.466px;
    flex-shrink: 0;
    top: 55px;
    fill: rgba(2, 50, 10, 0.03);
    position: absolute;
    right: -200px;
    z-index: 1;
}

.faq_main_container{
    display: flex;
    width: 100%;
    max-width: 1200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
}

.faq_main_container .heading{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.faq_main_container .heading .title{
    color:#000;
    font-family: 'Inter';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; 
    letter-spacing: -0.32px;
    text-transform: capitalize;
}

.faq_main_container .heading .desc{
    max-width: 852px;
    color: #5D5A5A;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.faq_main_container .heading .desc a{
    text-decoration: underline;
    color: #5D5A5A;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.faq_main_container .contents{
    display: flex;
    align-items: flex-start;
    gap: 40px;
    flex-wrap: wrap;
}

.faq_main_container .contents .item{
    width: calc(50% - 40px);
    display: flex;
    align-items: flex-start;
    gap: 24px;
}

.faq_main_container .contents .item .icon{
    border-radius: 10px;
    border: 2px solid #D9D9D9;
    background: #FFF;
    display: flex;
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    padding: 2px 3px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.faq_main_container .contents .item .icon svg{
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    flex-shrink: 0;
}

.faq_main_container .contents .item .text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    position: relative;
    width: 100%;
}

.faq_main_container .contents .item .text .dropdown_icon{
    position: absolute;
    right: -18px;
    top: 6px;
    transform: rotate(0deg);
    transition: 0.3s ease-in-out;
    cursor: pointer;
    z-index: 2;
}

.faq_main_container .contents .item .text .dropdown_icon.rotate{
    transform: rotate(180deg);
}

.faq_main_container .contents .item .text .question{
    color: #000;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    cursor: pointer;
    z-index: 2;
}

.faq_main_container .contents .item .text .answer{
    color: #494545;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    overflow: hidden;
    transition: 0.3s ease-in-out;
}

@media screen and (max-width:1025px){
    .faq_main_container .contents .item .icon{
        min-width: 30px;
        min-height: 30px;
        width: 30px;
        height: 30px;
    }
    
    .faq_main_container .contents .item .icon svg{
        width: 15px;
        height: 15px;
        min-width: 15px;
        min-height: 15px;
    }
}

@media screen and (max-width:800px) {
    .faq_main_container .contents .item{
        width: 100%;
    }
}