.home_service {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 32px;
  width: 100%;
  background-color: #8a0204;
}

.home_service_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  max-width: 1200px;
}

.home_service_container .heading h1 {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 10px;
  border: 1px solid #525151;
  border-radius: 4px;
  background-color: #ee1b22;
}

.home_service_container .content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 30px;
  column-gap: 40px;
}

.home_service_container .content .item {
  display: flex;
  width: 100%;
  padding: 20px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border: 1px solid #525151;
  border-radius: 4px;
  background-color: #ee1b22;
}

.home_service_container .content .item .title {
  color: #fff;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.home_service_container .content .item .desc {
  color: var(--color-neutral-50, #f5f5f5);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home_service_container .content .item a {
  color: #2b308c;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.home_service_container .content .item a svg {
  height: 20px;
  width: 20px;
}

@media screen and (max-width: 585px) {
  .home_service_container .content {
    grid-template-columns: repeat(1, 1fr);
  }
}
