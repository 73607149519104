.whyus_main{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
    position: relative;
}

.whyus_main .leftsvg{
    width: 643px;
    height: 493px;
    flex-shrink: 0;
    fill: rgba(2, 50, 10, 0.03);
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-50%,50%);
}
.whyus_main .rightsvg{
    width: 684.966px;
    height: 571.466px;
    flex-shrink: 0;
    top: 55px;
    fill: rgba(2, 50, 10, 0.03);
    position: absolute;
    right: -200px;
}

.whyus_main_container{
    width: 100%;
    max-width: 1200px;
}

.whyus_main_container .upper,
.whyus_main_container .lower{
    padding: 50px 0;
    display: flex;
    align-items: flex-start;
    gap: 40px;
    width: 100%;
}

.whyus_main_container .upper .image img,
.whyus_main_container .lower .image img{
    width: 500px;
    height: 498px;
    object-fit: cover;
    border-radius: 5px;
}

.whyus_main_container .upper .content,
.whyus_main_container .lower .content{
    display: flex;
    padding: 10px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}

.whyus_main_container .upper .content .text,
.whyus_main_container .lower .content .text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.whyus_main_container .upper .content .text .title,
.whyus_main_container .lower .content .text .title{
    color: #000;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.18px;
}

.whyus_main_container .upper .content .text .desc,
.whyus_main_container .lower .content .text .desc{
    color: #5D5A5A;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
}

.whyus_main_container .upper .content .text .head{
    color: #000;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
}

.whyus_main_container .lower .content .text .last{
    color: #000;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.16px;
}

@media screen and (max-width:950px) {
    .whyus_main_container .upper{
        flex-direction: column;
    }

    .whyus_main_container .lower{
        flex-direction: column-reverse;
    }

    .whyus_main_container .upper .image,
    .whyus_main_container .lower .image{
        width: 100%;
    }

    .whyus_main_container .upper .image img,
    .whyus_main_container .lower .image img{
        width: 100%;
        height: 498px;
    }
}