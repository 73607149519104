
.profile_top_heading{
    width: 100%;
    padding: 24px 42px;
    border-radius: 5px;
    border: 1px solid #EAEBF0;
    background: var(--base-white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}

.profile_top_heading_container{
    width: 100%;
    display: flex;
    gap: 24px;
    align-items: flex-end;
    align-self: stretch;
}

.profile_top_logo{
    min-width: 168px;
    position: relative;
}

.profile_top_logo div{
    width: 100%;
    height: 168px;
    padding: 12px;
    border-radius: 15px;
    border: 8px solid var(--primary-50, #F5F8FE);
    background: var(--base-white, #FFF);
    position: absolute;
    bottom: 0px;
}


.profile_top_logo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.profile_top_content{
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1 0 0;
}

.profile_top_content_upper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
}

.profile_top_content_upper div{
    display: flex;
    align-items: center;
    gap: 16px;
}

.profile_top_content_upper div h2{
    color: #272D37;
    font-family: 'Inter';
    font-weight: 600;
    line-height: 38px;
    letter-spacing: -0.28px;
}

.profile_top_content_upper div p{
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 2px 8px;
    border-radius: 5px;
    background:#5390F8;
    color: #fff;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.1px;
}

.profile_top_content_upper button{
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 5px;
    background: #437EF7;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
    border: none;
    outline: none;
    color: #fff;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.profile_top_content_upper button svg{
    width: 15px;
    height: 13.5px;
    stroke-width: 1.67px;
    stroke: #fff;
}

.profile_top_content_lower{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    font-family: 'Inter';
    color: #5F6D7E;
    line-height: 24px;
    letter-spacing: -0.1px;
}

.profile_top_content_lower h5{
    color: #1A75FF;
    font-size: 16px;
    font-weight: 600;
}

.profile_top_content_lower p{
    font-size: 16px;
    font-weight: 400;
}

@media screen and (max-width:500px){
    .profile_top_heading_container{
        flex-wrap: wrap;
    }
}