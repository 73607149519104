.faq_contact{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 32px;
}

.faq_contact_container{
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    border-radius: 4px;
    background: rgba(2, 50, 10, 0.10);
}

.faq_contact_container .upper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.faq_contact_container .upper .images{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: fit-content;
    position: relative;
}

.faq_contact_container .upper .images img{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 1px solid #F9F9F9;
    object-fit: cover;
}

.faq_contact_container .upper .images img.image2{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.faq_contact_container .upper .text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.faq_contact_container .upper .text .title{
    color: #000;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.faq_contact_container .upper .text .desc{
    color: #727070;
    text-align: center;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.faq_contact_container .button{
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #02320A;
}

.faq_contact_container .button a{
    color: #fff;
    text-align: center;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}