#footer{
    padding-top: 64px;
    border-top: 1px solid #E4E7EC;
}

.footer_container{
    margin: 0px 112px;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    font-family: 'Inter';
    gap: 32px;
}

.footer_container .stLine{
    width: 100%;
    height: 1px;
    background-color: #E4E7EC;
}

.footer_content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.footer_about .footer_title{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    font-family: 'Inter';
    color: #090B0F;
}

.footer_about .footer_desc{
    font-weight: 400;
    font-size: 16px;
    margin: 24px 0 32px;
}

.footer_about .footer_desc p{
    font-size: 16px;
    color: #344051;
    font-weight: 400;
    font-style: normal;
    line-height: 24px;
    font-family: 'Inter'
}

.footer_about .social_links{
    display: flex;
    justify-content: flex-start;
    gap: 12px;
}

.footer_about .social_links a svg{
    fill: #707174;
    width: 24px;
    height: 24px;
}

.footer_company, 
.footer_contact,
.footer_support,
.footer_about{
    display: flex;
    flex-direction: column;
    margin: 20px 20px 20px 0;
}

.footer_company a, 
.footer_contact a,
.footer_support a{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color:#344051;
    margin-top: 10px;
    font-style: normal;
    font-family: 'Inter'
}

.footer_contact a{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.footer_contact a svg{
    width: 20px;
    height: 20px;
}

.footer_company a:hover, 
.footer_contact a:hover,
.footer_support a:hover{
    color: #02320A;
}

.footer_content_title{
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #141C24;
    font-style: normal;
    font-family: 'Inter'
}

.footer_button{
    align-self: flex-start;
}

.footer_info{
    align-self: flex-start;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.footer_info .copyright p,
.footer_info .legal a{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: 'Inter';
    color: #141C24;
    font-style: normal;
}

.footer_info .legal a{
    color: #1A75FF;
    margin: 0 10px;
}

@media screen and (max-width:650px){
    .footer_container{
        margin: 0 30px;
    }
}

@media screen and (max-width:1200px){
    .footer_container{
        margin: 0 56px;
    }
    .footer_info{
        flex-direction: column;
    }
    .footer_info .legal{
        margin: 20px 0;
    }
}