.newsletter_container {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #141c24;
  padding: 115px 0 64px;
}

.newsletter_container.black {
  background-color: #910c0c;
  color: #fff;
}

.newsletter_container .newsletter_title {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  margin: 0 10px 16px 10px;
  text-align: center;
}

.newsletter_container .newsletter_desc {
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin: 0 10px 16px 10px;
  text-align: center;
  color: #2b3142;
}

.newsletter_container.black .newsletter_desc {
  color: #fff;
}

.newsletter_container button {
  margin-top: 60px;
}
