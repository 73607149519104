.service_page{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 32px;
    position: relative;
}

.service_page_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    gap: 10px;
}

.service_page_container .heading{
    display: flex;
    padding: 30px 0px;
    align-items: flex-start;
    gap: 10px;
}

.service_page_container .heading p{
    color: #000;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.service_page_container .content{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 40px;
    row-gap: 30px;
}

.service_page_container .content .item{
    display: flex;
    width: 100%;
    padding-top: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    border: 1px solid rgba(10, 15, 31, 0.08);
    background: #FFF;
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 12px 20px;
}

.service_page_container .content .item .title{
    color: var(--color-black, #000);
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.service_page_container .content .item .desc{
    color: var(--color-neutral-700, #5D5A5A);
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

@media screen and (max-width:650px){
    .service_page_container .content{
        grid-template-columns: repeat(1,1fr);
    }
}