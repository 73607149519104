.card {
  width: 384px;
  border-radius: 10px;
  border: 1px solid var(--neutral-600, #eaebf0);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}

.card .img_container {
  position: relative;
  width: 100%;
  height: 215px;
}

.card .img_container img.partner_logo {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: absolute;
  bottom: -20px;
  right: 24px;
  object-fit: contain;
  background-color: #fff;
}

.card .img_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.card .card_content {
  padding: 20px 32px 24px;
}
.card_content .card_name svg {
  height: 25px;
  width: 25px;
}

.card_title {
  font-family: "Inter";
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.22px;
  color: #272d37;
}

.card_desc {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #5f6d7e;
  margin: 16px 0 24px;
  text-align: justify;
}

.card_link a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #2b308c;
  gap: 6px;
}

.card_link a {
  font-family: "Inter";
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #2b308c;
}

.adminOption {
  position: absolute;
  right: -20px;
  top: 30px;
  display: flex;
  flex-direction: column;
  background-color: rgb(209, 204, 204);
  padding: 18px 20px;
  border-radius: 8px;
  gap: 10px;
}

.adminOption a,
.adminOption button {
  font-family: "Inter";
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #eaebf0;
  padding: 8px 12px;
  background-color: #437ef7;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

.adminOption button {
  background-color: rgb(204, 70, 70);
}

@media screen and (max-width: 25rem) {
  .partner_cards.marketplace .card {
    width: 95%;
  }

  .partner_cards.marketplace .card .img_container img.partner_logo {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: absolute;
    bottom: -20px;
    right: 24px;
  }

  .partner_cards.marketplace .card .img_container img {
    width: 100%;
  }
}
