.alert{
    width: fit-content;
    background: #0275d8;
    border-radius: 6px;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 500;
    position: fixed;
    top: 83px;
    left: 50%;
    transform: translate(-50%);
    z-index: 101;
    border: 1px solid #000;
}

.alert_message{
    color: #fff;
}

.alert_line{
    display: block;
    width: calc(100% - 16px);
    height: 4px;
    background-color: #1C2534;
    margin: 0 8px 6px;
    animation: width 5s;
}

@keyframes width{
    0%{
        width: 0;
    }
    25%{
        width: calc(75% - 16px)
    }
    100%{
        width: calc(100% - 16px);
    }
}

.alert_message{
    padding: 22px 32px 16px;
    text-align: center;
}

.alert.success{
    background: #5cb85c;
}

.alert.danger{
    background: #d9534f;
}

.alert.warning{
    background: #f0ad4e;
}