.challenges_main{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
    position: relative;
}

.challenges_main .leftsvg{
    width: 643px;
    height: 493px;
    flex-shrink: 0;
    fill: rgba(2, 50, 10, 0.03);
    position: absolute;
    bottom: 0;
    transform: translate(-50%,50%);
    left: 0;
}
.challenges_main .rightsvg{
    width: 684.966px;
    height: 571.466px;
    flex-shrink: 0;
    top: 55px;
    fill: rgba(2, 50, 10, 0.03);
    position: absolute;
    right: -200px;
}

.challenges_main_container{
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.challenges_main_container .upper{
    padding: 50px 0;
}

.challenges_main_container .lower{
    padding: 0 0 50px;
}

.challenges_main_container .upper,
.challenges_main_container .lower{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.challenges_main_container .upper .heading,
.challenges_main_container .lower .heading{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.challenges_main_container .upper .heading .title,
.challenges_main_container .lower .heading .title{
    color: #000;
    font-family: 'Inter';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.challenges_main_container .upper .heading .desc,
.challenges_main_container .lower .heading .desc{
    color: #727070;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.challenges_main_container .upper .content,
.challenges_main_container .lower .content{
    display: grid;
    padding: 10px 0px;
    gap: 40px;
    grid-template-columns: repeat(2,1fr);
    grid-auto-rows: 1fr;
}

.challenges_main_container .upper .content .text,
.challenges_main_container .lower .content .text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    border: 1px solid rgba(10, 15, 31, 0.08);
    background: #FFF;
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 12px 20px;
}

.challenges_main_container .upper .content .text .title,
.challenges_main_container .lower .content .text .title{
    color: #000;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.challenges_main_container .upper .content .text .desc,
.challenges_main_container .lower .content .text .desc{
    color: #5D5A5A;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.challenges_main_container .last{
    border: 1px solid rgba(10, 15, 31, 0.08);
    background: #FFF;
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 12px 20px;
    margin-bottom: 28px;
}

.challenges_main_container .last p{
    color: #000;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

@media screen and (max-width:700px) {
    .challenges_main_container .upper .content,
    .challenges_main_container .lower .content{
        grid-template-columns: repeat(1,1fr);
    }

    .challenges_main_container .upper .content .text,
    .challenges_main_container .lower .content .text{
        width: 100%;
    }
}