.container {
  padding-top: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;
}

.img {
  width: 447px;
  height: 350px;
}

.img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 450px) {
  .img {
    width: 247px;
    height: 250px;
  }
}
