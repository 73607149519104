.about_hero{
    padding: 80px  80px;
    background: rgba(2, 50, 10, 0.10);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about_hero_container{
    max-width: 874px;
    width: 100%;
    text-align: center;
    font-family: 'Inter';
    color: #fff;
}

.about_hero_text{
    color: #02320A;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.1px;
    margin-bottom: 25px;
}

.about_hero_title{
    font-weight: 600;
    font-size: 32px;
    line-height: normal;
    letter-spacing: 1px;
    color: #000;
}

.about_hero_desc{
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    color: #727070;
    margin-top: 16px;
}

@media screen and (max-width:500px) {
    .about_hero{
        padding: 80px  20px;
    }
}

@media screen and (max-width:450px){
    .about_hero_text{
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }
    
    .about_hero_title{
        font-size: 30px;
        line-height: 42px;
    }
    
    .about_hero_desc{
        font-size: 14px;
        font-weight: 300;
    }
}