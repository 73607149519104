.about_frame{
    padding: 50px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about_frame_container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    max-width: 1200px;
}

.about_frame_container .image{
    width: 500px;
    height: 450px;
    border-radius: 4px;
    object-fit: cover;
}

.about_frame_container .content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.about_frame_container .content .title{
    color: #000;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.1px;
}

.about_frame_container .content .desc{
    color: #000;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    letter-spacing: -0.1px;
}

.about_frame_container .content .desc.readmore{
    overflow: hidden;
    transition: 0.3s ease-in-out;
}

.about_frame_container .content .button{
    color: #3B67FF;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.1px;
    cursor: pointer;
}

@media screen and (max-width:850px) {
    .about_frame_container{
        flex-direction: column;
        align-items: center;
    }

    .about_frame_container .image{
        width: 100%;
    }
}

@media screen and (max-width:400px) {
    .about_frame{
        padding: 20px;
    }
}