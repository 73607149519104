.contact_details{
    font-family: 'Inter';
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
    max-width: 328px;
}

.contact_details h1{
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.1px;
    color: #F9F9F9;
}

.contact_desc_address,
.contact_details_desc{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #A5ACBA;
    margin-top: 16px;
}

.contact_details_icon{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: fit-content;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #A5ACBA;
    margin-top: 8px;
}

.contact_details_social_links{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.contact_details_social_links div{
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.contact_details_social_links div a{
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    border: 1px solid #2E3545;
    background: #2C3444;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}

.contact_details_social_links div a svg{
    height: 24px;
    width: 24px;
    color: #fff;
}