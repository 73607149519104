#about{
    display: flex;
    flex-direction: column;
}

.about_header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 66px 150px 48px;
}

.about_header .about_title{
    font-family: 'Inter';
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.64px;
    color: #090B0F;
    margin-bottom: 15px;
    text-align: center;
}

.about_header .about_desc{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #2B3142;
    text-align: center;
}

.about_content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 64px 150px;
    background: #090B0F;
}

.about_content .about_images {
    max-width: 560px;
    max-height: 560px;
    height: 560px;
    width: 560px;
}

.about_content .about_images img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about_content_desc{
    display: flex;
    flex-direction: column;
    color: #fff;
    margin-left: 72px;
    gap: 36px;
}

.about_topics .topic_title{
    font-family: 'Inter';
    font-weight: 600;
    font-size: 22px;
    line-height: 42px;
    letter-spacing: -0.22px;
    color: #fff;
}

.about_topics .topic_desc{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #B1CCFB;
}

.about_button{
    margin-top: 60px;
}

@media screen and (max-width:1200px){
    .about_content{
        padding: 64px 30px;
        text-align: center;
    }

    .about_content_desc{
        margin-left: 20px;
    }
}

@media screen and (max-width:768px){
    .about_content .about_images{
        max-width: 300px;
        max-height: 200px;
        width: 300px;
        height: 200px;
        margin-bottom: 30px;
    }

    .about_header{
        margin-left: 50px;
        margin-right: 50px;
    }

    .about_content{
        flex-direction: column;
        padding: 64px 30px;
        text-align: center;
    }

    .about_content_desc{
        margin-left: 0;
    }
}