.container {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}
.left {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.title {
  font-family: "Inter";
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #272d37;
  letter-spacing: -0.64px;
}
.image_container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.image_container img {
  width: 258px;
  height: 172px;
  border-radius: 15px;
}
.right {
  width: 50%;
}
.desc {
  /* margin: 10px 0; */
  /* font-family: Arial, sans-serif; */
  line-height: 1.6;
  padding: 0 50px;
  text-align: justify;
}
@media (max-width: 1000px) {
  .container {
    flex-direction: column;
  }
  .left {
    width: 100%;
  }
  .right {
    width: 100%;
  }
}
