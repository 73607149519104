.home_hero {
  padding: 0 88px;
  background-image: url("../../../images/Hero.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.hero_container {
  padding: 80px 30px 92px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hero_title {
  font-family: "Inter";
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 10px;
}

h2.hero_content {
  font-family: "Inter";
  font-weight: 700;
  font-size: 32px;
  line-height: 46px;
  text-align: center;
  color: #ffffff;
}

p.hero_content {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 28px;
  line-height: 46px;
  text-align: center;
  color: #ffffff;
}

.hero_buttons {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 46px;
}

.hero_buttons button.secondary-btn {
  border: 0.8px solid #fff;
}

.container_class {
  display: flex;
  max-width: 90%;
  height: 575px;
  width: 90%;
  /* justify-content: center; */
}
.item_class {
  max-width: 90%;
}

.carousel-class {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 100px;
}

@media screen and (max-width: 814px) {
  .home_hero {
    padding: 0 44px;
  }
  .carousel-class {
    padding-left: 10px;
  }
}

@media screen and (max-width: 650px) {
  .home_hero {
    padding: 0;
  }

  .hero_container {
    padding: 80px 10px 92px;
  }

  .hero_title {
    font-size: 16px;
  }

  h2.hero_content {
    font-size: 24px;
  }

  p.hero_content {
    font-size: 20px;
  }

  .hero_title,
  h2.hero_content,
  p.hero_content {
    padding: 5px;
    font-weight: 600;
  }
}
