.contact_email{
    padding: 32px;
    background: #1C2534;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
}

.inputs_flex{
    display: flex;
    gap: 24px;
}

.inputs{
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: 'Inter';
    width: 376px;
}

.inputs label{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.1px;
    color: #F9F9F9;
}

.inputs input,
.inputs textarea{
    padding: 12px 16px;
    border-radius: 6px;
    border: 1px solid var(--gray-300, #2E3545);
    background: var(--gray-600, #2C3444);
    outline: none;
    color: #A5ACBA;
    width: calc(200% + 24px);
}

.inputs input{
    height: 46px;
    width: 100%;
}

.check_container{
    display: flex;
    gap: 12px;
}

.check_container p{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #F9F9F9;
}

.check_container p span{
    color:rgba(0, 87, 255, 1);
    cursor: pointer;
}

.checkbox{
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1.67px solid var(#2E3545);
    background: var(--gray-800, #252D3C);
    cursor: pointer;
}

.checkbox svg{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    color: rgba(0, 87, 255, 1);
}

.checkbox .unchecked{
    color: #2E3545;
}

.submit_btn button{
    background: #0057FF;
    color: #F9F9F9;
}

.submit_btn button:disabled{
    background-color: #252D3C;
    color: #2E3545;
    cursor: not-allowed;
}

@media screen and (max-width:880px) {
    .inputs{
        width: 340px;
    }
}

@media screen and (max-width:800px) {
    .inputs{
        width: 250px;
    }
}

@media screen and (max-width:650px) {
    .inputs{
        width: 98%;
    }

    .inputs textarea{
        width: 100%;
    }

    .inputs_flex{
        flex-direction: column;
    }

    .submit_btn button{
        width: 100%;
    }
}
