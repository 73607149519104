.onboard {
  padding: 0px 112px 41px;
}

.onboard_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.onboard_partner_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 32px 0;
  margin-bottom: 64px;
  position: sticky;
  top: 100px;
  z-index: 20;
  background-color: #ffffff;
}

.tags {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tags .tag_title {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #272d37;
  margin-right: 28px;
}

.tags .tag {
  position: relative;
}

.tags .tag {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #272d37;
  margin-right: 40px;
  cursor: pointer;
}

.tags .tag.active,
.tags .tag:hover {
  color: #11131c;
  font-weight: 600;
}

.tags .tag:hover .underline,
.tags .tag.active .underline {
  height: 4px;
  width: 30px;
  background: #11131c;
  border-radius: 100px;
  position: absolute;
  top: 17px;
}

.search {
  display: flex;
}

.search_content {
  padding: 12px;
  display: flex;
  align-items: center;
  width: 382px;
  border: 1px solid #cdd1d8;
  border-radius: 5px 0px 0px 5px;
}

.search_icon {
  color: #919ba7;
  width: 18.33px;
  height: 18.33px;
  margin: 0 10px 0 0;
}

.searchTerm {
  width: 330px;
  font-family: "Inter";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.1px;
  line-height: 22px;
  color: #919ba7;
  outline: none;
  border: none;
}

.search_button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: #ffffff;
}

a.addPartner {
  width: fit-content;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 18px;
  color: #b1ccfb;
  background-color: #11131c;
  border: 0.8px solid #2b3142;
  border-radius: 6px;
  font-family: "Inter";
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

a.addPartner:hover {
  color: #11131c;
  background-color: #b1ccfb;
}

@media screen and (max-width: 1438px) {
  .onboard {
    padding: 41px 32px;
  }
}

@media screen and (max-width: 650px) {
  .onboard {
    padding: 41px 15px;
  }

  .onboard_partner_search {
    padding-right: 15px;
  }

  .tags {
    margin-bottom: 20px;
  }

  .tags .tag_title {
    margin-right: 16px;
  }

  .tags .tag {
    margin-right: 16px;
  }

  .search_content {
    width: 280px;
  }
}

@media screen and (max-width: 1200px) {
  .onboard {
    padding: 41px 0 41px 25px;
  }

  .onboard_partner_search {
    flex-direction: column;
    padding-right: 25px;
  }

  .tags {
    margin-bottom: 20px;
  }
  a.addPartner {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 380px) {
  .onboard {
    padding: 41px 0 41px 10px;
  }

  .onboard_partner_search {
    padding-right: 10px;
  }

  .search_content {
    width: 200px;
  }

  .tags .tag,
  .tags .tag_title {
    margin-right: 8px;
  }
}
