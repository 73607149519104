.profile{
    background: var(--neutral-100, #F8F9FB);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profile_top{
    width: 100%;
    height: 270px;
    position: relative;
}

.profile_top div{
    width: 100%;
    height:350px;
    position: absolute;
    bottom: -80px;
    background-image: url(../../images/PartnerProfile.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.position_relative_profile{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 32px 128px;
    width: 100%;
    max-width: 1216px;
}

.profile_bottom{
    width: 100%;
    max-width: 1216px;
    margin-top: 16px;
    display: flex;
    gap: 16px;
    align-items: flex-start;
}

@media screen and (max-width:700px) {
    .profile_bottom{
        flex-direction: column-reverse;
    }
}

@media screen and (max-width:650px) {
    .position_relative_profile{
        padding: 0 16px 128px;
    }
}