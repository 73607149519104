.faq_hero{
    background: rgba(2, 50, 10, 0.10);
    display: flex;
    padding: 50px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.faq_hero_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    max-width: 874px;
}

.faq_hero_container .top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #727070;
}

.faq_hero_container .top .title{
    color: #02320A;
    text-align: center;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.1px;
}

.faq_hero_container .top .heading{
    color: #000;
    text-align: center;
    font-family: 'Inter';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 45px;
}

.faq_hero_container .top .desc{
    color: #727070;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.faq_hero_container .bottom{
    display: flex;
    width: 100%;
    max-width: 450px;
    padding: 15px 30px;
    align-items: center;
    gap: 20px;
    border-radius: 5px;
    background: #fff;
    position: relative;
}

.faq_hero_container .bottom input{
    color: #727070;
    font-family: 'Inter';
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
    border: none;
}

.faq_hero_container .bottom svg{
    height: 20px;
    width: 20px;
    color: #727070;
}

.faq_hero_container .bottom svg.close{
    position: absolute;
    right: 30px;
    cursor: pointer;
    display: none;
}

.faq_hero_container .bottom svg.close.active{
    display: block;
}

@media screen and (max-width:450px){
    .faq_hero_container .top .title{
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }
    
    .faq_hero_container .top .heading{
        font-size: 30px;
        line-height: 42px;
    }
    
    .faq_hero_container .top .desc{
        font-size: 14px;
        font-weight: 300;
    }
}