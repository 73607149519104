.dropdown{
    width: 100%;
    position: relative;
}

.dropdown_select{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 46px;
    padding: 12px 16px;
    border-radius: 6px;
    border: 1px solid var(--gray-300, #2E3545);
    background: var(--gray-600, #2C3444);
    outline: none;
    color: #A5ACBA;
    cursor: pointer;
    transition: background 0.3s;
}

.dropdown_select span{
    color: #A5ACBA;
    font-size: 15px;
    font-family: 'Inter';
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.1px;
}

/* .dropdown_select-clicked{
    border: 2px #26489a solid;
    box-shadow: 0 0 0.8em #26489a;
} */

/* .dropdown_select:hover{
    background: #d2d3d6;
} */

.dropdown_caret{
    /* width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #fff; */
    transition: 0.3s;
}

.dropdown_select .dropdown_caret svg{
    color: #A5ACBA;
}

.dropdown_caret-rotate{
    transform: rotate(180deg);
}

.dropdown_menu{
    list-style: none;
    padding: 0.2em 0.5em;
    background: #d2d3d6;
    border: 1px solid #d2d3d6;
    box-shadow: 0 0.5em 1em rgba(0,0,0,0.2);
    border-radius: 0.5em;
    position: absolute;
    top: 3em;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    opacity: 0;
    display: none;
    transition: 0.2s;
    z-index: 1;
}

.dropdown_menu li{
    padding: 0.7em 0.5em;
    margin: 0.3em 0;
    border-radius: 0.5em;
    cursor: pointer;
} 

.dropdown_menu li:hover{
    background: #aaaaaa;
}

.dropdown_active{
    background: #aaaaaa;
}

.dropdown_menu-open{
    display: block;
    opacity: 1;
}