.team {
  padding: 0 112px;
}

.team_container {
  margin: 64px 0 32px 0;
  display: flex;
  flex-direction: column;
}

.team_headline {
  text-align: center;
  font-family: "Inter";
  margin: 0 208px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.team_headline h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #272d37;
}

.team_headline p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #5f6d7e;
}

.team_cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  gap: 64px;
}

.team_button {
  display: flex;
  justify-content: center;
}

.team_button button {
  margin: 10px 0 80px 0;
}

@media screen and (max-width: 1442px) {
  .team {
    padding: 0 42px;
  }
}

@media screen and (max-width: 1300px) {
  .team {
    padding: 0 112px;
  }
  .team_cards {
    flex-wrap: nowrap;
    max-width: 100%;
    justify-content: flex-start;
    overflow-x: scroll;
    gap: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .team_headline {
    margin: 0;
  }
}

@media screen and (max-width: 650px) {
  .team {
    padding: 0 20px;
  }

  .team_headline {
    margin: 0;
  }
}
