.team_card {
  display: flex;
  flex-wrap: wrap;
  margin: 32px 0;
  width: fit-content;
}

.teamcard_image {
  width: 276px;
  height: 260px;
  margin-right: 24px;
}

.teamcard_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  object-position: top;
}

.team_description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 276px;
}
.team_name_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  position: relative;
}

.memb_name {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #272d37;
  align-self: stretch;
}
.team_name_container svg {
  height: 25px;
  width: 25px;
}
.memb_position,
.memb_desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #2b308c;
  align-self: stretch;
}

.memb_desc {
  color: #5f6d7e;
  margin: 16px 0;
}

.memb_social_links a svg {
  margin-right: 16px;
  color: #707174;
  fill: #707174;
  width: 24px;
  height: 24px;
}

.memb_social_links a svg:hover {
  color: #02320a;
  fill: #02320a;
}
@media (max-width: 1300px) {
  .team_name_container {
    width: 80%;
  }
}

@media screen and (max-width: 650px) {
  .team_card {
    justify-content: center;
    align-items: center;
  }

  .teamcard_image {
    margin-right: 0;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 1200px) {
  .teamcard_image {
    width: 250px;
    height: 250px;
    margin-right: 18px;
  }
}
